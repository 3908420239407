<template>
  <div class="stock">
    <v-card width="100%" rounded="lg" flat class="pb-7 pt-sm-8 accent px-sm-0 px-4">
      <div class="d-flex flex-wrap justify-space-between mb-5 black--text">
        <div class="d-flex align-center">
          <v-avatar class="me-4" :size="$vuetify.breakpoint.xs ? 56 : 90" color="accent" v-if="data.company_picture">
            <v-img contain :src="data.company_picture"></v-img>
          </v-avatar>
          <div>
            <div class="f30">{{ data.exchange_symbol }}</div>
            <div class="f18 gray--text">{{ data.exchange_id }}</div>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-3 align-center text-end">
          <div class="text-h6 text-uppercase me-5 d-flex align-center" v-if="data.portfolio_stock_cost">
            <v-img class="me-2" max-width="36" src="@/assets/icon/wallet-circle.svg"></v-img>
            <div>{{ data.price_currency }} {{ (data.portfolio_stock_cost / 100).toFixed(2) }}</div>
          </div>
          <v-btn
            :class="data.portfolio_percentage ? 'primary white--text' : 'white'"
            class="d-sm-inline-flex d-none"
            outlined
            height="60"
            width="240"
            @click.stop="data.portfolio_percentage ? '' : addItem(data)"
          >
            <img height="24" :src="getIcon(data.portfolio_percentage ? 'portfolioW' : 'portfolio')" class="pe-3" />
            <span v-if="data.portfolio_percentage" class="pe-3">{{ data.portfolio_percentage / 100 }}%</span>
            {{ data.portfolio_percentage ? $t('btn.inPorfolio') : $t('btn.addPorfolio') }}
          </v-btn>
        </div>
      </div>
      <div class="mb-sm-10 mb-5 black--text">
        <span class="text-h4 font-weight-bold me-3 text-uppercase">{{ data.price_currency }} {{ (data.price / 100).toFixed(2) }}</span>
        <!-- <span class="gray--text text-body-2">
          <span class="triangle-top"></span> +325,26 (51,36 %) {{ $t('stockList.forYear') }}
        </span> -->
      </div>
      <v-btn
        :class="data.portfolio_percentage ? 'primary white--text' : 'white'"
        class="d-sm-none mb-8"
        outlined
        height="48"
        width="100%"
        max-width="343"
        @click.stop="data.portfolio_percentage ? '' : addItem(data)"
      >
        <img height="24" :src="getIcon(data.portfolio_percentage ? 'portfolioW' : 'portfolio')" class="pe-3" />
        <span v-if="data.portfolio_percentage" class="pe-3">{{ data.portfolio_percentage / 100 }}%</span>
        {{ data.portfolio_percentage ? $t('btn.inPorfolio') : $t('btn.addPorfolio') }}
      </v-btn>
      <v-tabs background-color="transparent" v-model="tab" class="mb-sm-0 mb-6">
        <v-tabs-slider class="d-sm-block d-none" color="primary"></v-tabs-slider>
        <v-tab
          @click="openGraf(dataGraf)"
          v-for="item in $t('stockItem.tabs')"
          :key="item"
          class="text-body-1 font-weight-bold text-none gray--text"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-divider class="mb-7 d-sm-block d-none" style="background: #ebeff4"></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item class="black--text pa-sm-7" :class="$vuetify.breakpoint.xs ? 'accent' : ''">
          <div class="text-h5 font-weight-bold mb-2">{{ data.company_name }}</div>
          <div class="text-capitalize gray--text mb-6 text-sm-body-1 text-body-2" v-if="data.sector">{{ data.sector.name }}</div>
          <div>{{ data.company_details }}</div>
        </v-tab-item>
        <v-tab-item>
          <v-card tile flat id="stock" class="pa-sm-7" :class="$vuetify.breakpoint.xs ? 'accent' : ''">
            <div class="d-sm-flex">
              <!-- <div class="text-end text-uppercase mb-3">
                <span
                  :style="period == item ? 'border-radius: 10px; background: #ebeff4' : ''"
                  class="link d-inline-block mx-2 text-center py-1 px-2"
                  @click="getPeriod(item)"
                  v-for="item in periodList"
                  :key="item"
                >
                  {{ item }}
                </span>
              </div> -->
              <v-spacer></v-spacer>
              <div
                :class="$vuetify.breakpoint.xs ? 'width100 d-flex justify-space-around mb-4' : 'mb-5'"
                class="gray lighten-1 rounded-lg"
                style="padding: 6px; height: 48px"
              >
                <span
                  :class="period == item ? 'rounded-lg white' : ''"
                  class="link d-inline-block mx-2 text-center py-2 px-5"
                  @click="getPeriod(item)"
                  v-for="item in periodList"
                  :key="item"
                >
                  {{ item }}
                </span>
              </div>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { createChart } from 'lightweight-charts';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      number: '',
      period: '1m',
      periodList: ['1d', '1w', '1m', '1y'],
      count: 0,
      tab: 0,
      grafic: '',
      chart: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getSecuritiesItem');
    this.getPeriod(this.period);
  },
  methods: {
    getIcon(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    openGraf(data) {
      if (!this.grafic) {
        setTimeout(() => {
          this.getGraf(data);
        }, 200);
      }
    },
    async addItem(i) {
      this.error = [];
      const data = new Object();
      data.percent = 0;
      data.security = i.id;
      await this.$store
        .dispatch('addPlanItem', data)
        .then(() => {
          this.$router.push('/portfolio/plan');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((i) => i == 'security__already_added') ? (this.snackbar = true) : '';
          this.error.find((i) => i == 'questionnaire__not_provided') ? this.$router.push(`/question/start?page=${this.$route.path}`) : '';
        });
    },
    async getPeriod(i) {
      this.period = i;
      await this.$store.dispatch('getSecuritiesItemPrice', i);
    },
    getGraf(data) {
      this.chart = createChart(document.querySelector('#stock'), {
        width: document.querySelector('#stock').offsetWidth - `${this.$vuetify.breakpoint.xs ? 0 : 60}`,
        layout: {
          backgroundColor: this.$vuetify.breakpoint.xs ? '#f9fafd' : '#fff',
          textColor: '#909ab0',
        },
        height: 400,
        localization: {
          locale: 'en-US',
          priceFormatter: (price) => {
            return `SAR ${price.toFixed(2)}`;
          },
        },
        handleScroll: { mouseWheel: false, pressedMouseMove: true },
        handleScale: { axisPressedMouseMove: false, mouseWheel: false, pinch: false },
        rightPriceScale: {
          scaleMargins: {
            top: 0.02,
          },
        },
        grid: {
          vertLines: {
            visible: false,
          },
        },
      });
      this.grafic = this.chart.addAreaSeries({
        topColor: 'rgba(0, 146, 188, 0.56)',
        bottomColor: 'rgba(0, 146, 188, 0.04)',
        lineColor: 'rgba(0, 146, 188, 1)',
        lineWidth: 2,
      });
      this.grafic.setData(data);
      this.chart.timeScale().fitContent();
      var toolTipWidth = 90;
      var toolTipHeight = 90;
      var toolTipMargin = 5;
      var toolTip = document.createElement('div');
      toolTip.className = 'floating-tooltip-2';
      document.querySelector('#stock').appendChild(toolTip);
      this.chart.subscribeCrosshairMove((param) => {
        if (
          param.point === undefined ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > document.querySelector('#stock').clientWidth ||
          param.point.y < 0 ||
          param.point.y > document.querySelector('#stock').clientHeight
        ) {
          toolTip.style.display = 'none';
        } else {
          toolTip.style.display = 'block';
          var price = param.seriesPrices.get(this.grafic);
          toolTip.innerHTML = `
            <div>SAR ${price}</div>
            <div>
              ${new Date(param.time * 1000).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  })}
            </div>
          `;
          var coordinate = this.grafic.priceToCoordinate(price);
          var shiftedCoordinate = param.point.x - 55;
          if (coordinate === null) {
            return;
          }
          shiftedCoordinate = Math.max(0, Math.min(document.querySelector('#stock').clientWidth - toolTipWidth, shiftedCoordinate));
          var coordinateY =
            coordinate - toolTipHeight - toolTipMargin > 0
              ? coordinate - toolTipHeight - toolTipMargin
              : Math.max(
                0,
                Math.min(document.querySelector('#stock').clientHeight - toolTipHeight - toolTipMargin, coordinate + toolTipMargin)
              );
          toolTip.style.left = shiftedCoordinate + 'px';
          toolTip.style.top = coordinateY + 'px';
        }
      });
    },
  },
  watch: {
    dataGraf() {
      if (this.grafic) {
        this.grafic.setData(this.dataGraf);
        this.chart.timeScale().fitContent();
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.securitiesItem;
    },
    dataGraf() {
      return this.$store.getters.securitiesItemPrice;
    },
    numberErrors() {
      const errors = [];
      this.error.find((item) => item == 'phone__required') && errors.push('Please enter your phone number');
      this.error.find((item) => item == 'phone__invalid') && errors.push('Provided phone number is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setSecuritiesItem', {});
    this.$store.dispatch('setSecuritiesItemPrice', []);
  },
};
</script>

<style lang="scss">
.stock {
  .v-tab--active {
    color: var(--v-primary-base) !important;
  }
  .v-divider {
    margin-top: -2px;
    height: 2px;
    border-width: 1px;
    border-color: #ebeff4;
  }
  .row .v-divider {
    height: 1px;
    border-width: 1px;
    border-top-width: 0;
    border-color: #ebeff4;
  }
  @media screen and (max-width: 599px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .text-h5,
    .f30 {
      font-size: 1.125rem !important;
    }
    .text-h4 {
      font-size: 1.5rem;
    }
    .f18 {
      font-size: 1rem;
    }
    #stock {
      background-color: var(--v-accent-base) !important;
    }
  }
}
</style>
